.pdf-page-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  padding: 0 0 50px 0;
  align-items: center;
  overflow-y: scroll;
}

.top-bar {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  z-index: 1000;
  gap: 16px;
}

.top-bar div {
  flex: 1;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.selected {
  background-color: #0A0A10;
  color: #ececec;
  transition: background-color 0.3s ease-in-out;
  position: relative;
  z-index: 1001;
  border-radius: 8px;
}

.unselected {
  background-color: #222536;
  color: #ececec;
  transition: background-color 0.3s ease-in-out;
  position: relative;
  z-index: 1001;
  border-radius: 8px;
}

.checkmark-icon {
  fill: green;
  background-color: green;
  color: green;
  height: 16px;
  width: 16px;
}

.pdf-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0 0 0 !important;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  border-radius: 5px;
}

.pdf-page-button-wrapper p {
  text-align: center;
}

.pdf-page-button-wrapper button {
  width: 100px;
  margin: 0 8px;
}

.pdf-page-kvkk-button-area {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin: 12px 0;
  height: 68px !important;
}

.pdf-page-kvkk-button-area button {
  flex: 1;
}

.react-pdf__Page__canvas {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.react-pdf__Page__textContent {
  border-radius: 0 !important;
}



/* For Mobile Users */
@media  only screen and (max-width: 415px) {
  .confirm-button {
    position: fixed;
    bottom: 0;
    border-radius: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    height: 48px !important;
  }
  
  .pdf-page-container {
    max-width: 100% !important;
  }

  .confirmed-text {
    position: fixed;
    bottom: 0;
    border-radius: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    height: 48px !important;
  }

  .top-bar {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0 !important;
    gap: 0 !important;
    margin: 0 0;
    z-index: 1000;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .pdf-page-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .pdf-page-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .selected,
  .unselected {
    border-radius: 0 !important;
  }
}